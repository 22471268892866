import React, { useState, useRef, useCallback, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Form, {
    Item,
    Label,
    ButtonItem,
    ButtonOptions,
    RequiredRule,
    CustomRule,
    EmailRule,
} from 'devextreme-react/form';
import FileUploader from 'devextreme-react/file-uploader';
import SelectBox, { SelectBoxTypes } from 'devextreme-react/select-box';
import notify from 'devextreme/ui/notify';
import LoadIndicator from 'devextreme-react/load-indicator';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../../contexts/auth';
import axios from 'axios';
import { backendlink } from '../../config';
import { Toast } from 'devextreme-react/toast';

import './PartnerEditForm.scss';
import { Button } from 'devextreme-react';

export default function PartnerEditForm() {

    const { user } = useAuth();

    const navigate = useNavigate();

    const [partnerToken, setPartnerToken] = useState("")

    const location = useLocation();

    const [loading, setLoading] = useState(false);
    const [loadingContractAgreement, setLoadingContractAgreement] = useState(false);
    const [loadingPricingAgreement, setLoadingPricingAgreement] = useState(false);
    const [loadingEvaluationAndServiceAgreement, setLoadingEvaluationAndServiceAgreement] = useState(false);
    const [loadingQuotation, setLoadingQuotation] = useState(false);
    const [loadingPo, setLoadingPo] = useState(false);

    const [uploadedContractAgreement, setUploadedContractAgreement] = useState(false);
    const [uploadedPricingAgreement, setUploadedPricingAgreement] = useState(false);
    const [uploadedEvaluationAndServiceAgreement, setUploadedEvaluationAndServiceAgreement] = useState(false);
    const [uploadedQuotation, setUploadedQuotation] = useState(false);
    const [uploadedPo, setUploadedPo] = useState(false);

    const [formData, setformData] = useState({ id: '', contractId:'', username: '', companyname: '', companyaddress: '', email: '', phone: '', subscriptionplan: '', startDate: '', endDate:'', contactperson: '', usertype: '', downpaymentreceived: '' });
    const formDataRef = useRef(null)
    const [subscriptionPlan, setSubscriptionPlan] = useState('');


    const [toastConfig, setToastConfig] = useState({
        isVisible: false,
        type: 'success',
        message: '',
    });

    function onHiding() {
        setToastConfig({
            ...toastConfig,
            isVisible: false,
        });
    }

    function selectFile(item) {
        var input = document.createElement('input');
        input.type = 'file';
        input.accept = '.pdf'

        input.onchange = e => { 
            var file = e.target.files[0]; 

            console.log(file)

            uploadFile(item, file);
        }

        input.click();
    }

    function setSubLoading(item, value) {
        switch (item) {
            case 'contractAgreement':
                setLoadingContractAgreement(value)
                break;

            case 'pricingAgreement':
                setLoadingPricingAgreement(value)
                break;

            case 'evaluationAndServiceAgreement':
                setLoadingEvaluationAndServiceAgreement(value)
                break;

            case 'quotation':
                setLoadingQuotation(value)
                break;

            case 'po':
                setLoadingPo(value)
                break;
        
            default:
                break;
        }
    }

    function setUploaded(item) {
        switch (item) {
            case 'contractAgreement':
                setUploadedContractAgreement(true)
                break;

            case 'pricingAgreement':
                setUploadedPricingAgreement(true)
                break;

            case 'evaluationAndServiceAgreement':
                setUploadedEvaluationAndServiceAgreement(true)
                break;

            case 'quotation':
                setUploadedQuotation(true)
                break;

            case 'po':
                setUploadedPo(true)
                break;
        
            default:
                break;
        }
    }

    const uploadFile = async (item, file) => {
        const formData = new FormData();

        formData.append('file', file);
        formData.append('partnertoken', partnerToken);
        formData.append('document', item);

        setSubLoading(item, true)

        try {
            const response = await axios.post(backendlink + 'vitome/uploaddocuments',
            formData, 
            {
                headers: {
                    token: user.token
                }        
            }).then((res)=>{
                setSubLoading(item, false)

                if (res.data.errorno == 0){
                    setToastConfig({
                        ...toastConfig,
                        isVisible: true,
                        type: 'success',
                        // message: 'New partner has been added',
                        message: 'File uploaded.',
                    });    

                    setUploaded(item)
                    // formData.current.instance.clear();
                } else{
                    setToastConfig({
                        ...toastConfig,
                        isVisible: true,
                        type: 'error',
                        message: res.data.errmessage,
                    });    
                }
            });
        } catch (error) {
            switch (item) {
                case 'contractAgreement':
                    setLoadingContractAgreement(false)
                    break;
            
                default:
                    break;
            }

            // console.log(error);
            alert(error);
        }
    }

    const onSubmit = async (e) => {
        e.preventDefault();

        if (!validateEmail(formData.email)) {
            setToastConfig({
                ...toastConfig,
                isVisible: true,
                type: 'error',
                message: "Email format error",
            });
        } else if (!validateEmail(formData.username)) {
            setToastConfig({
                ...toastConfig,
                isVisible: true,
                type: 'error',
                message: "Login Email format error",
            });
        } else {
            setLoading(true);

            let startDate = new Date(formData.startDate);
            let formattedDate = `${startDate.getFullYear()}-${("0" + (startDate.getMonth() + 1)).slice(-2)}-${("0" + startDate.getDate()).slice(-2)}`;


            let endDate = new Date(formData.endDate);
            endDate.setDate(endDate.getDate() - 1); // Subtract one day
            let formattedDate1 = `${endDate.getFullYear()}-${("0" + (endDate.getMonth() + 1)).slice(-2)}-${("0" + endDate.getDate()).slice(-2)}`;


            try {
                const response = await axios.post(backendlink + 'vitome/editpartner',
                {
                    partnertoken: partnerToken,
                    contractId: formData.contractId,
                    username: formData.username,
                    companyname: formData.companyname,
                    companyaddress: formData.companyaddress,
                    email: formData.email,
                    phone: formData.phone,
                    subscriptionplan: formData.subscriptionplan,
                    startDate: formattedDate + 'T00:00:00.000Z',
                    endDate: formattedDate1 + 'T23:59:59.000Z',
                    contactperson: formData.contactperson,
                    downpaymentreceived: formData.downpaymentreceived,
                }, 
                {
                    headers: {
                        token: user.token
                    }        
                }).then((res)=>{
                    setLoading(false);

                    if (res.data.errorno == 0){
                        setToastConfig({
                            ...toastConfig,
                            isVisible: true,
                            type: 'success',
                            // message: 'New partner has been added',
                            message: 'Partner updated',
                        });    

                        // formData.current.instance.clear();
                    } else{
                        setToastConfig({
                            ...toastConfig,
                            isVisible: true,
                            type: 'error',
                            message: res.data.errmessage,
                        });    
                    }
                });
            } catch (error) {
                setLoading(false);

                // console.log(error);
                alert(error);
            }
        }
    };

    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    useEffect(() => {
        if (location.state?.partnerToken == null) {
            navigate('/');
        } else {
            setPartnerToken(location.state.partnerToken);

            const fetchData = async () => {
                try {
                    const response = await axios.post(backendlink + 'vitome/getpartner',
                    {
                        partnertoken: location.state.partnerToken
                    },
                    {
                        headers: {
                            token: user.token
                        }
                    });
    
                    if (response.data.errorno == 0) {
                        // console.log(response.data.result)
    
                        const outputArray = response.data.result.map(item => {
    
                            var usertypeString = item.usertype
    
                            switch (item.usertype) {
                                case "CP":
                                    usertypeString = "Channel Partner"
                                    break;
    
                                case "DIST":
                                    usertypeString = "Distributor"
                                    break;
                            
                                default:
                                    break;
                            }
    

                            setformData({
                                id: item.id, 
                                contractId: item.contractId,
                                username: item.username, 
                                companyname: item.companyname, 
                                companyaddress: item.companyaddress, 
                                email: item.email, 
                                phone: item.phone, 
                                subscriptionplan: item.subscriptionplan, 
                                startDate: item.contractStartDate.split('T')[0],
                                endDate: item.contractEndDate.split('T')[0],
                                contactperson: item.contactperson,
                                usertype: usertypeString, 
                                userToken: item.userToken,
                                downpaymentreceived: parseInt(item.downpaymentStatus),
                            });
                        });
                    } else {
                        alert(response.data.errmessage);
                    }
                } catch (error) {
                    // console.log(error);
                    alert(error);
                }
            };

            const fetchData1 = async () => {
                try {
                    const response = await axios.post(backendlink + 'vitome/getsubscriptionplans',
                        {
                        },
                        {
                            headers: {
                                token: user.token
                            }
                        });
    
                    if (response.data.errorno == 0) {
                        setSubscriptionPlan(response.data.result[0]);
    
                    } else {
                        alert(response.data.errmessage);
                    }
                } catch (error) {
                    // console.log(error);
                    alert(error);
                }
            };
    
            fetchData();
    
            fetchData1();


        }
    }, [user]);

    const readonlyptions = { readOnly: 'true' };
    const dateoptions = user.usertype === 'ADMIN' ? { displayFormat: 'yyyy-MM-dd', readOnly: true } : { displayFormat: 'yyyy-MM-dd' };

    var userTypeItems = [
        { id: 'CP', text: 'Channel Partner'},
        { id: 'DIST', text: 'Distributor'},
    ];

    var downpaymentItems = [
        { id: 0, text: 'Not Paid' },
        { id: 1, text: 'Paid' },
    ];

    return (
        <form onSubmit={onSubmit}>
            <Form formData={formData} ref={formDataRef} disabled={false}>
                {/* <ButtonItem>
                    <ButtonOptions
                    width={'auto'}
                    type={'default'}
                    onClick={pressEdit}
                    >
                    <span className="dx-button-text">
                        Edit
                    </span>
                    </ButtonOptions>
                </ButtonItem> */}

                <Item
                    dataField={'id'}
                    editorType={'dxTextBox'}
                    editorOptions={readonlyptions}
                >
                    <Label text='Partner ID' />
                </Item>

                <Item
                    dataField={'usertype'}
                    editorType={'dxTextBox'}
                    editorOptions={readonlyptions}
                >
                    <Label text='Partner Type' />
                </Item>

                {/* <Item
                    dataField={'usertype'}
                    editorType={'dxSelectBox'}
                    editorOptions= {{
                        items: userTypeItems,
                        displayExpr: 'text',
                        valueExpr: 'id'
                    }}
                >
                    <Label text='Partner Type' />
                </Item> */}

                <Item
                    dataField={'companyname'}
                    editorType={'dxTextBox'}
                >
                    <Label text='Customer Name' />
                </Item>

                <Item
                    dataField={'companyaddress'}
                    editorType={'dxTextBox'}
                >
                    <Label text='Company Address' />
                </Item>

                <Item
                    dataField={'contactperson'}
                    editorType={'dxTextBox'}
                >
                    <Label text='Contact Person' />
                </Item>

                <Item
                    dataField={'phone'}
                    editorType={'dxTextBox'}
                >
                    <Label text='Phone Number' />
                </Item>

                <Item
                    dataField={'email'}
                    editorType={'dxTextBox'}
                >
                    <Label text='Contact Email' />
                </Item>

                <Item
                    dataField={'subscriptionplan'}
                    editorType={'dxSelectBox'}
                    editorOptions={{
                        items: subscriptionPlan,
                        displayExpr: 'tierName',
                        valueExpr: 'subscriptionPlanId',
                        ...(user.usertype === 'ADMIN' ? { readOnly: true } : { readOnly: false })

                    }}                >
                    <Label text='Subscription Plan' />
                </Item>

                {/* <Item
                    dataField={'contractperiod'}
                    editorType={'dxTextBox'}
                >
                    <Label text='Contract Period' />
                </Item> */}
                <Item
                    dataField={'startDate'}
                    editorType={'dxDateBox'}
                    editorOptions={dateoptions}
                >
                    <Label text='Contract Start Date' />
                </Item>

                <Item
                    dataField={'endDate'}
                    editorType={'dxDateBox'}
                    editorOptions={dateoptions}
                >
                    <Label text='Contract End Date' />
                </Item>

                <Item
                    dataField={'downpaymentreceived'}
                    editorType={'dxSelectBox'}
                    editorOptions={{
                        items: downpaymentItems,
                        displayExpr: 'text',
                        valueExpr: 'id'
                    }}
                >
                    <Label text='Down Payment Received' />
                </Item>

                {/* login email */}
                <Item
                    dataField={'username'}
                    editorType={'dxTextBox'}
                >
                    <Label text='Login Email' />
                </Item>

                <ButtonItem
                    horizontalAlignment={'left'}
                >
                    <ButtonOptions
                        width={'auto'}
                        type={'default'}
                        onClick={() => {selectFile("contractAgreement")}}
                    >
                        <span className="dx-button-text">
                        {
                            loadingContractAgreement
                            ? <LoadIndicator width={'24px'} height={'24px'} visible={true} />
                            : ('Upload Contract Agreement' + (uploadedContractAgreement == true ? ' ✔︎' : ''))
                        }
                        </span>
                    </ButtonOptions>
                </ButtonItem>

                <ButtonItem
                    horizontalAlignment={'left'}
                >
                    <ButtonOptions
                        width={'auto'}
                        type={'default'}
                        onClick={() => {selectFile("pricingAgreement")}}
                    >
                        <span className="dx-button-text">
                        {
                            loadingPricingAgreement
                            ? <LoadIndicator width={'24px'} height={'24px'} visible={true} />
                            : ('Upload Pricing Agreement' + (uploadedPricingAgreement == true ? ' ✔︎' : ''))
                        }
                        </span>
                    </ButtonOptions>
                </ButtonItem>

                <ButtonItem
                    horizontalAlignment={'left'}
                >
                    <ButtonOptions
                        width={'auto'}
                        type={'default'}
                        onClick={() => {selectFile("evaluationAndServiceAgreement")}}
                    >
                        <span className="dx-button-text">
                        {
                            loadingEvaluationAndServiceAgreement
                            ? <LoadIndicator width={'24px'} height={'24px'} visible={true} />
                            : ('Upload Evaluation and Service Agreement' + (uploadedEvaluationAndServiceAgreement == true ? ' ✔︎' : ''))
                        }
                        </span>
                    </ButtonOptions>
                </ButtonItem>

                <ButtonItem
                    horizontalAlignment={'left'}
                >
                    <ButtonOptions
                        width={'auto'}
                        type={'default'}
                        onClick={() => {selectFile("quotation")}}
                    >
                        <span className="dx-button-text">
                        {
                            loadingQuotation
                            ? <LoadIndicator width={'24px'} height={'24px'} visible={true} />
                            : ('Upload Quotation' + (uploadedQuotation == true ? ' ✔︎' : ''))
                        }
                        </span>
                    </ButtonOptions>
                </ButtonItem>

                <ButtonItem
                    horizontalAlignment={'left'}
                >
                    <ButtonOptions
                        width={'auto'}
                        type={'default'}
                        onClick={() => {selectFile("po")}}
                    >
                        <span className="dx-button-text">
                        {
                            loadingPo
                            ? <LoadIndicator width={'24px'} height={'24px'} visible={true} />
                            : ('Upload PO' + (uploadedPo == true ? ' ✔︎' : ''))
                        }
                        </span>
                    </ButtonOptions>
                </ButtonItem>

                <Item>
                    <span>*only pdf file is supported</span>
                </Item>
                
                <ButtonItem>
                    <ButtonOptions
                        width={'100%'}
                        type={'default'}
                        useSubmitBehavior={true}
                    >
                        <span className="dx-button-text">
                        {
							loading
							? <LoadIndicator width={'24px'} height={'24px'} visible={true} />
							: ('Update Partner')
						}
                        </span>
                    </ButtonOptions>
                </ButtonItem>
            </Form>

            <Toast
                visible={toastConfig.isVisible}
                message={toastConfig.message}
                type={toastConfig.type}
                onHiding={onHiding}
                displayTime={2000}
            />
        </form>
    )
}