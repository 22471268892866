import DataGrid, {
    Column,
    Pager,
    Paging,
    FilterRow,
    Lookup,
    Export,
    HeaderFilter
} from 'devextreme-react/data-grid';
import Form, {
    Label,
    ButtonItem,
    ButtonOptions,
    RequiredRule,
    CustomRule,
    EmailRule,
} from 'devextreme-react/form';
import LoadIndicator from 'devextreme-react/load-indicator';
import React, { useState, useEffect, useRef } from 'react';
import { useAuth } from '../../contexts/auth';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import TabPanel, { Item } from "devextreme-react/tab-panel";
import axios from 'axios';
import { backendlink } from '../../config';
import './EditHistoryDatagrid.css';

export default function EditHistoryDatagrid() {

    const { user } = useAuth();

    const navigate = useNavigate();

    const [users, setUsers] = useState([]);

    const location = useLocation();

    const [formData, setformData] = useState({ startDate: '', endDate: '' });
    const formDataRef = useRef(null);

    useEffect(() => {
        // console.log(user.token)
        // console.log(user.userToken)
        // console.log(user.accessToken)

        const fetchData = async () => {
            try {
                const response = await axios.post(backendlink + 'vitome/edithistory',
                    {},
                    {
                        headers: {
                            token: user.token
                        }
                    });
                if (response.data.errorno == 0) {
                    console.log(response.data.result)

                    const outputArray = response.data.result[0].map(item => {
                        var dateCreatedAt = new Date(item.createdAt)
                        // var createdAtString = dateCreatedAt.getFullYear() + '-' + (((dateCreatedAt.getMonth()+1) <= 10) ? ('0' + (dateCreatedAt.getMonth()+1)) : (dateCreatedAt.getMonth()+1)) + '-' + (((dateCreatedAt.getDate()) <= 10) ? ('0' + (dateCreatedAt.getDate())) : (dateCreatedAt.getDate()));
                        var createdAtString = dateCreatedAt.getFullYear() + '-' +
                            (((dateCreatedAt.getMonth() + 1) <= 9) ? ('0' + (dateCreatedAt.getMonth() + 1)) : (dateCreatedAt.getMonth() + 1)) + '-' +
                            ((dateCreatedAt.getDate() <= 9) ? ('0' + dateCreatedAt.getDate()) : dateCreatedAt.getDate()) + ' ' +
                            ((dateCreatedAt.getHours() <= 9) ? ('0' + dateCreatedAt.getHours()) : dateCreatedAt.getHours()) + ':' +
                            ((dateCreatedAt.getMinutes() <= 9) ? ('0' + dateCreatedAt.getMinutes()) : dateCreatedAt.getMinutes()) + ':' +
                            ((dateCreatedAt.getSeconds() <= 9) ? ('0' + dateCreatedAt.getSeconds()) : dateCreatedAt.getSeconds());

                        // var historyString = item.content != null ? item.content.replace(/[TZ]/g, ' ') : '';
                        var historyString = item.content;
                        if (historyString != null) {
                            historyString = historyString.replace(/T00:00:00\.000Z/g, "");
                            historyString = historyString.replace(/T23:59:59\.000Z/g, " 23:59:59");
                        }
                        
                        return { admin: item.admin, user: item.user, field: item.field, history: historyString, createdAt: createdAtString };
                    });
                    setUsers(outputArray);
                }
                else {
                    alert(response.data.errmessage);
                }
            } catch (error) {
                // console.log(error);
                alert(error);
            }
        };

        fetchData();

    }, []);

    const dateoptions = { displayFormat: 'yyyy-MM-dd' };

    return (
        <React.Fragment>
            <DataGrid dataSource={users}                 columnAutoWidth={true}
            >
                <Column dataField='createdAt' caption='Date' />
                <Column dataField='admin' caption='Admin User' />
                <Column dataField='user' caption='Dist / Partner' />
                <Column dataField='field' caption='Field' />
                <Column dataField='history' caption='History'/>

                <Pager allowedPageSizes={[5, 10, 20]} showPageSizeSelector={true} />
                <Paging defaultPageSize={5} />
                <FilterRow visible={true} />
                <Lookup />
                {/* <Export enabled={true} /> */}
                {/* <HeaderFilter visible={true} /> */}
            </DataGrid>

        </React.Fragment>
    )

}