import React, { useState, useRef, useCallback, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Form, {
    Item,
    Label,
    ButtonItem,
    ButtonOptions,
} from 'devextreme-react/form';
import SelectBox, { SelectBoxTypes } from 'devextreme-react/select-box';
import notify from 'devextreme/ui/notify';
import LoadIndicator from 'devextreme-react/load-indicator';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../../contexts/auth';
import axios from 'axios';
import { backendlink } from '../../config';
import { Toast } from 'devextreme-react/toast';

import './PartnerAddForm.scss';

export default function PartnerAddForm() {

    const { user } = useAuth();

    const navigate = useNavigate();

    const [partnerToken, setPartnerToken] = useState("")

    const location = useLocation();

    const [loading, setLoading] = useState(false);
    const [formData, setformData] = useState({ usertype: '', username: '', password: '', confirmPassword: '', companyname: '', companyaddress: '', email: '', phone: '', subscriptionplan: '', contractperiod: '', contactperson: '', usertype: '', downpaymentreceived: 0 });
    const formDataRef = useRef(null)
    const [subscriptionPlan, setSubscriptionPlan] = useState('');

    const [toastConfig, setToastConfig] = useState({
        isVisible: false,
        type: 'success',
        message: '',
    });

    function onHiding() {
        setToastConfig({
            ...toastConfig,
            isVisible: false,
        });
    }

    const onSubmit = async (e) => {
        e.preventDefault();

        if (!validateEmail(formData.email)) {
            setToastConfig({
                ...toastConfig,
                isVisible: true,
                type: 'error',
                message: "Email format error",
            });
        } else if (!validateEmail(formData.username)) {
            setToastConfig({
                ...toastConfig,
                isVisible: true,
                type: 'error',
                message: "Login Email format error",
            });
        } else {
            let startDate = new Date(formData.startDate);
            let formattedDate = `${startDate.getFullYear()}-${("0" + (startDate.getMonth() + 1)).slice(-2)}-${("0" + startDate.getDate()).slice(-2)}`;


            let endDate = new Date(formData.endDate);
            // endDate.setDate(endDate.getDate() - 1); // Subtract one day

            let formattedDate1 = `${endDate.getFullYear()}-${("0" + (endDate.getMonth() + 1)).slice(-2)}-${("0" + endDate.getDate()).slice(-2)}`;

            // console.log('aaaaa', partnerToken)

            setLoading(true);

            try {
                const response = await axios.post(backendlink + 'vitome/createpartner',
                    {
                        usertype: formData.usertype,
                        username: formData.username,
                        password: formData.password,
                        confirmPassword: formData.confirmPassword,
                        companyname: formData.companyname,
                        companyaddress: formData.companyaddress,
                        email: formData.email,
                        phone: formData.phone,
                        subscriptionplan: formData.subscriptionplan,
                        // contractperiod: formData.contractperiod,
                        startDate: formattedDate + 'T00:00:00.000Z',
                        endDate: formattedDate1 + 'T23:59:59.000Z',
                        downpaymentreceived: formData.downpaymentreceived,
                        contactperson: formData.contactperson
                    },
                    {
                        headers: {
                            token: user.token
                        }
                    }).then((res) => {
                        setLoading(false);

                        if (res.data.errorno == 0) {
                            setToastConfig({
                                ...toastConfig,
                                isVisible: true,
                                type: 'success',
                                message: 'New partner has been added',
                            });

                            setformData({ usertype: '', username: '', password: '', confirmPassword: '', companyname: '', companyaddress: '', email: '', phone: '', subscriptionplan: '', startDate: '', endDate: '', contactperson: '', usertype: '', downpaymentreceived: 0 })
                        } else {
                            setToastConfig({
                                ...toastConfig,
                                isVisible: true,
                                type: 'error',
                                message: res.data.errmessage,
                            });
                        }
                    });
            } catch (error) {
                setLoading(false);

                // console.log(error);
                alert(error);
            }
        }
    };

    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.post(backendlink + 'vitome/getsubscriptionplans',
                    {
                    },
                    {
                        headers: {
                            token: user.token
                        }
                    });

                if (response.data.errorno == 0) {
                    setSubscriptionPlan(response.data.result[0]);

                } else {
                    alert(response.data.errmessage);
                }
            } catch (error) {
                // console.log(error);
                alert(error);
            }
        };

        fetchData();

        
        var dateStartdate = new Date();
        var dateEnddate = new Date();
        dateEnddate.setFullYear(dateEnddate.getFullYear() + 1);
        dateEnddate.setDate(dateEnddate.getDate() - 1);
        
        var startDateString = `${dateStartdate.getFullYear()}-${("0" + (dateStartdate.getMonth() + 1)).slice(-2)}-${("0" + dateStartdate.getDate()).slice(-2)}`;
        var endDateString = `${dateEnddate.getFullYear()}-${("0" + (dateEnddate.getMonth() + 1)).slice(-2)}-${("0" + dateEnddate.getDate()).slice(-2)}`;
        
        setformData({ startDate: startDateString, endDate: endDateString, downpaymentreceived: 0 })

    }, []);

    const passwordOptions = { mode: "password" };
    const dateoptions = { displayFormat: 'yyyy-MM-dd' };

    var userTypeItems = [
        { id: 'CP', text: 'Channel Partner' },
        { id: 'DIST', text: 'Distributor' },
    ];

    var downpaymentItems = [
        { id: 0, text: 'Not Paid' },
        { id: 1, text: 'Paid' },
    ];

    return (
        <form onSubmit={onSubmit}>
            <Form formData={formData} ref={formDataRef} disabled={false}>

                <Item
                    dataField={'usertype'}
                    editorType={'dxSelectBox'}
                    editorOptions={{
                        items: userTypeItems,
                        displayExpr: 'text',
                        valueExpr: 'id'
                    }}
                >
                    <Label text='Partner Type' />
                </Item>

                <Item
                    dataField={'companyname'}
                    editorType={'dxTextBox'}
                >
                    <Label text='Customer Name' />
                </Item>

                <Item
                    dataField={'companyaddress'}
                    editorType={'dxTextBox'}
                >
                    <Label text='Company Address' />
                </Item>

                <Item
                    dataField={'contactperson'}
                    editorType={'dxTextBox'}
                >
                    <Label text='Contact Person' />
                </Item>

                <Item
                    dataField={'phone'}
                    editorType={'dxTextBox'}
                >
                    <Label text='Phone Number' />
                </Item>

                <Item
                    dataField={'email'}
                    editorType={'dxTextBox'}
                >
                    <Label text='Contact Email' />
                </Item>

                <Item
                    dataField={'subscriptionplan'}
                    editorType={'dxSelectBox'}
                    editorOptions={{
                        items: subscriptionPlan,
                        displayExpr: 'tierName',
                        valueExpr: 'subscriptionPlanId'
                    }}                >
                    <Label text='Subscription Plan' />
                </Item>

                {/* <Item
                    dataField={'contractperiod'}
                    editorType={'dxTextBox'}
                >
                    <Label text='Contract Period' />
                </Item> */}
                <Item
                    dataField={'startDate'}
                    editorType={'dxDateBox'}
                    editorOptions={dateoptions}
                >
                    <Label text='Contract Start Date' />
                </Item>

                <Item
                    dataField={'endDate'}
                    editorType={'dxDateBox'}
                    editorOptions={dateoptions}
                >
                    <Label text='Contract End Date' />
                </Item>

                <Item
                    dataField={'downpaymentreceived'}
                    editorType={'dxSelectBox'}
                    editorOptions={{
                        items: downpaymentItems,
                        displayExpr: 'text',
                        valueExpr: 'id'
                    }}
                >
                    <Label text='Down Payment Received' />
                </Item>

                {/* login email */}
                <Item
                    dataField={'username'}
                    editorType={'dxTextBox'}
                >
                    <Label text='Login Email' />
                </Item>

                <Item
                    dataField={'password'}
                    editorType={'dxTextBox'}
                    editorOptions={passwordOptions}
                >
                    <Label text='Password' />
                </Item>

                <Item
                    dataField={'confirmPassword'}
                    editorType={'dxTextBox'}
                    editorOptions={passwordOptions}
                >
                    <Label text='Confirm Password' />
                </Item>

                <ButtonItem>
                    <ButtonOptions
                        width={'100%'}
                        type={'default'}
                        useSubmitBehavior={true}
                    >
                        <span className="dx-button-text">
                            {
                                loading
                                    ? <LoadIndicator width={'24px'} height={'24px'} visible={true} />
                                    : ('Create a Partner Account')
                            }
                        </span>
                    </ButtonOptions>
                </ButtonItem>
            </Form>

            <Toast
                visible={toastConfig.isVisible}
                message={toastConfig.message}
                type={toastConfig.type}
                onHiding={onHiding}
                displayTime={2000}
            />
        </form>
    )
}