import React, { useState, useRef, useCallback, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Form, {
    Item,
    Label,
    ButtonItem,
    ButtonOptions,
} from 'devextreme-react/form';
import SelectBox, { SelectBoxTypes } from 'devextreme-react/select-box';
import notify from 'devextreme/ui/notify';
import LoadIndicator from 'devextreme-react/load-indicator';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../../contexts/auth';
import axios from 'axios';
import { backendlink } from '../../config';
import { Toast } from 'devextreme-react/toast';

import './AdminAddForm.scss';

export default function AdminAddForm() {

    const { user } = useAuth();

    const navigate = useNavigate();

    const [partnerToken, setPartnerToken] = useState("")

    const location = useLocation();

    const [loading, setLoading] = useState(false);
    const [formData, setformData] = useState({ usertype: '', username: '', password: '', confirmPassword: '', companyname: '', companyaddress: '', email: '', phone: '', subscriptionplan: '', contractperiod: '', contactperson: '', usertype: '', downpaymentreceived: 0 });
    const formDataRef = useRef(null)
    const [subscriptionPlan, setSubscriptionPlan] = useState('');

    const [toastConfig, setToastConfig] = useState({
        isVisible: false,
        type: 'success',
        message: '',
    });

    function onHiding() {
        setToastConfig({
            ...toastConfig,
            isVisible: false,
        });
    }

    const onSubmit = async (e) => {
        e.preventDefault();

        if (!validateEmail(formData.email)) {
            setToastConfig({
                ...toastConfig,
                isVisible: true,
                type: 'error',
                message: "Email format error",
            });
        } else if (!validateEmail(formData.username)) {
            setToastConfig({
                ...toastConfig,
                isVisible: true,
                type: 'error',
                message: "Login Email format error",
            });
        } else {

            setLoading(true);

            try {
                const response = await axios.post(backendlink + 'vitome/createadmin',
                    {
                        username: formData.username,
                        password: formData.password,
                        confirmPassword: formData.confirmPassword,
                        name: formData.name,
                        email: formData.email,
                    },
                    {
                        headers: {
                            token: user.token
                        }
                    }).then((res) => {
                        setLoading(false);

                        if (res.data.errorno == 0) {
                            setToastConfig({
                                ...toastConfig,
                                isVisible: true,
                                type: 'success',
                                message: 'New Admin has been added',
                            });

                            setformData({ usertype: '', username: '', password: '', confirmPassword: '', companyname: '', companyaddress: '', email: '', phone: '', subscriptionplan: '', startDate: '', endDate: '', contactperson: '', usertype: '', downpaymentreceived: 0 })
                        } else {
                            setToastConfig({
                                ...toastConfig,
                                isVisible: true,
                                type: 'error',
                                message: res.data.errmessage,
                            });
                        }
                    });
            } catch (error) {
                setLoading(false);

                // console.log(error);
                alert(error);
            }
        }
    };

    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    const passwordOptions = { mode: "password" };


    return (
        <form onSubmit={onSubmit}>
            <Form formData={formData} ref={formDataRef} disabled={false}>

                <Item
                    dataField={'name'}
                    editorType={'dxTextBox'}
                >
                    <Label text='Name' />
                </Item>


                <Item
                    dataField={'email'}
                    editorType={'dxTextBox'}
                >
                    <Label text='Email' />
                </Item>

                {/* login email */}
                <Item
                    dataField={'username'}
                    editorType={'dxTextBox'}
                >
                    <Label text='Login Email' />
                </Item>

                <Item
                    dataField={'password'}
                    editorType={'dxTextBox'}
                    editorOptions={passwordOptions}
                >
                    <Label text='Password' />
                </Item>

                <Item
                    dataField={'confirmPassword'}
                    editorType={'dxTextBox'}
                    editorOptions={passwordOptions}
                >
                    <Label text='Confirm Password' />
                </Item>

                <ButtonItem>
                    <ButtonOptions
                        width={'100%'}
                        type={'default'}
                        useSubmitBehavior={true}
                    >
                        <span className="dx-button-text">
                            {
                                loading
                                    ? <LoadIndicator width={'24px'} height={'24px'} visible={true} />
                                    : ('Create a Admin Account')
                            }
                        </span>
                    </ButtonOptions>
                </ButtonItem>
            </Form>

            <Toast
                visible={toastConfig.isVisible}
                message={toastConfig.message}
                type={toastConfig.type}
                onHiding={onHiding}
                displayTime={2000}
            />
        </form>
    )
}