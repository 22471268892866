import DataGrid, {
    Column,
    Pager,
    Paging,
    FilterRow,
    Lookup,
    Export,
    HeaderFilter
} from 'devextreme-react/data-grid';
import React, { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/auth';
import { Link, useNavigate } from 'react-router-dom';
import TabPanel, { Item } from "devextreme-react/tab-panel";
import axios from 'axios';
import { backendlink } from '../../config';
import { EditHistoryDatagrid } from '../../components';

export default function EditHistoryPage() {
    return (
        <React.Fragment>
            <div className={'content-block dx-card responsive-paddings'} >

            <EditHistoryDatagrid />
            </div>
        </React.Fragment>
      );
  }