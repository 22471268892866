import DataGrid, {
    Column,
    Pager,
    Paging,
    FilterRow,
    Lookup,
    Export,
    HeaderFilter
} from 'devextreme-react/data-grid';
import React, { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/auth';
import { Link, useNavigate } from 'react-router-dom';
import TabPanel, { Item } from "devextreme-react/tab-panel";
import axios from 'axios';
import { backendlink } from '../../config';

export default function PartnerListDatagrid() {

    const { user } = useAuth();

    const navigate = useNavigate();

    const [users, setUsers] = useState([]);

    function handleRowClick(e) {
        const partnerToken = e.data.userToken;
        navigate('/partner-info', { state: { partnerToken: partnerToken } });
    }

    useEffect(() => {
        // console.log(user.token)
        // console.log(user.userToken)
        // console.log(user.accessToken)

        const fetchData = async () => {
            console.log("x", user.token)

            try {
                const response = await axios.post(backendlink + 'vitome/getallpartner',
                {
                    usertoken: user.userToken
                },
                {
                    headers: {
                        token: user.token
                    }
                });
                if (response.data.errorno == 0) {
                    // console.log(response.data.result)
                    const outputArray = response.data.result[0].map(item => {
                        var usertypeString = item.usertype

                        switch (item.usertype) {
                            case "CP":
                                usertypeString = "Channel Partner"
                                break;

                            case "DIST":
                                usertypeString = "Distributor"
                                break;
                        
                            default:
                                break;
                        }

                        return { partnerName: item.companyname, partnerID: item.id, partnerType: usertypeString, companyAddress: item.companyaddress, userToken: item.userToken };
                    });
                    setUsers(outputArray)
                }
                else {
                    alert(response.data.errmessage);
                }
            } catch (error) {
                // console.log(error);
                alert(error);
            }
        };

        fetchData();
    }, [user]);


    return (
        <React.Fragment>
            <DataGrid dataSource={users} onRowClick={handleRowClick}>
                <Column dataField='partnerName' caption='Partner Name' />
                <Column dataField='partnerID' caption='Partner ID' alignment='left' />
                <Column dataField='partnerType' caption='Partner Type' />
                <Column dataField='companyAddress' caption='Company Address' />

                <Pager allowedPageSizes={[5, 10, 20]} showPageSizeSelector={true} />
                <Paging defaultPageSize={5} />
                <FilterRow visible={true} />
                <Lookup />
                {/* <Export enabled={true} /> */}
                {/* <HeaderFilter visible={true} /> */}
            </DataGrid>
        </React.Fragment>
    )

}