import DataGrid, {
    Column,
    Pager,
    Paging,
    FilterRow,
    Lookup,
    Export,
    HeaderFilter
} from 'devextreme-react/data-grid';
import React, { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/auth';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import TabPanel, { Item } from "devextreme-react/tab-panel";
import axios from 'axios';
import { backendlink } from '../../config';
import './PatientListDatagrid.css';

export default function PatientListDatagrid() {

    const { user } = useAuth();

    const navigate = useNavigate();

    const [users, setUsers] = useState([]);

    const location = useLocation();

    function handleRowClick(e) {
        const customerToken = location.state.customerToken;
        const linkedPartner = location.state.linkedPartner;
        const customerName = location.state.customerName;
        const customerID = location.state.customerID;
        const doctorToken = location.state.doctorToken;
        const doctorName = location.state.doctorName;

        const patientToken = e.data.userToken;
        const patientName = e.data.name;

        navigate('/patient-log', { state: { customerToken: customerToken, linkedPartner: linkedPartner, customerName: customerName, customerID: customerID, doctorToken: doctorToken, doctorName: doctorName, patientToken: patientToken, patientName: patientName } });
    }

    useEffect(() => {
        // console.log(user.userToken)
        // console.log(user.accessToken)

        const fetchData = async () => {
            try {
                const response = await axios.post(backendlink + 'vitome/getallpatient',
                {
                    doctortoken: location.state.doctorToken
                },
                {
                    headers: {
                        token: user.token
                    }
                });
                if (response.data.errorno == 0) {
                    // console.log(response.data.result)

                    const outputArray = response.data.result[0].map(item => {
                        var dateCreatedAt = new Date(item.createdAt)
                        var createdAtString = dateCreatedAt.getFullYear() + '-' + (((dateCreatedAt.getMonth()+1) <= 10) ? ('0' + (dateCreatedAt.getMonth()+1)) : (dateCreatedAt.getMonth()+1)) + '-' + (((dateCreatedAt.getDate()) <= 10) ? ('0' + (dateCreatedAt.getDate())) : (dateCreatedAt.getDate()));

                        var statusString = (item.isEnabled == true ? 'Active' : 'Inactive')

                        return { name: item.name, email: item.email, status: statusString, createdAt: createdAtString, userToken: item.userToken };
                    });
                    setUsers(outputArray)
                }
                else {
                    alert(response.data.errmessage);
                }
            } catch (error) {
                // console.log(error);
                alert(error);
            }
        };

        fetchData();
    }, [user]);


    return (
        <React.Fragment>
            <DataGrid dataSource={users} onRowClick={handleRowClick}>
                <Column dataField='name' caption='Patient' />
                <Column dataField='email' caption='Contact Email' />
                <Column dataField='status' caption='Status' />
                <Column dataField='createdAt' caption='Created At' />

                <Pager allowedPageSizes={[5, 10, 20]} showPageSizeSelector={true} />
                <Paging defaultPageSize={5} />
                <FilterRow visible={true} />
                <Lookup />
                {/* <Export enabled={true} /> */}
                {/* <HeaderFilter visible={true} /> */}
            </DataGrid>
        </React.Fragment>
    )

}